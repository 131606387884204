<div class="root">
  <nz-layout>
    <nz-header>
      <div class="section__logo">
        <a class="section__logo__link" href="/">
          <img class="section__logo__pic" src="./assets/img/logo-1c.png">
          <span class="section__logo__text">
            1С:Бизнес-сеть
          </span>
        </a>
      </div>
      <div class="section__logout">
        <app-user-menu [userData]="userData$ | async"></app-user-menu>
      </div>
    </nz-header>
    <nz-content>
      <nz-card>
        <app-breadcrumbs [items]="breadcrumbsItems$ | async"></app-breadcrumbs>
        <router-outlet></router-outlet>
      </nz-card>
    </nz-content>
    <nz-footer>
      <span>© 2011–2020 ООО «1С-СОФТ»</span>
      <span>Служба поддержки <a href="mailto:bntech@1c.ru">bntech@1c.ru</a></span>
    </nz-footer>
  </nz-layout>
</div>
