import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from '#shared/modules/services';
import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Location } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _notifyService: NzNotificationService,
    private _location: Location,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this._authService.userData$
      .pipe(
        switchMap(x => !!x ? of(true) : this._authService.login(state.url)),
        catchError((x) => {
          return of(false);
        })
      );
  }
}


