import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SpinnerOverlayService } from './spinner-overlay.service';
import { SpinnerOverlayComponent } from './spinner-overlay.component';

@NgModule({
  declarations: [SpinnerOverlayComponent],
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    NzSpinModule,
  ],
  providers: [SpinnerOverlayService],
  exports: [SpinnerOverlayComponent],
  entryComponents: [SpinnerOverlayComponent]
})
export class SpinnerOverlayModule { }
