import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { environment } from '#environments/environment';
import { redirectTo } from '#shared/utils';
import { HttpClient } from '@angular/common/http';
import { map, filter } from 'rxjs/operators';
import { SpinnerOverlayService } from '../spinner-overlay/spinner-overlay.service';

const API_URL = environment.apiUrl;
const ITS_URL = environment.itsUrl;

class UserDataModel {
  public accessToken: string;
  public refreshToken: string;
  public accessTokenExpiration: string;
  public refreshTokenExpiration: string;
  public userInfo: {
    userId: string;
    login: string;
    fullName: string;
    phone: string;
    email: string;
  };

  constructor(params: any) {
    Object.assign(this, params);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData$: BehaviorSubject<UserDataModel> = new BehaviorSubject(null);

  static modifiedQueryString(): any {
    const str = location.search;
    const objURL = {};
    str.replace(
        new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
        ($0, $1, $2, $3) => {
          return objURL[$1] = $3;
        },
    );
    delete objURL['ticket'];
    // tslint:disable-next-line: max-line-length
    return Object.keys(objURL).map(key => key + '=' + objURL[key]).join('&') ? `?${Object.keys(objURL).map(key => key + '=' + objURL[key]).join('&')}` : '';
  }

  static getTicket(): string {
    const parseQueryString: Function = () => {
      const str = location.search;
      const objURL = {};
      str.replace(
          new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
          ($0, $1, $2, $3) => {
            return objURL[$1] = $3;
          },
      );
      return objURL;
    };
    return parseQueryString().ticket;
  }

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _spinnerOverlayService: SpinnerOverlayService,
  ) {}

  setUserData(data: any, fromNextTick = true) {
    if (fromNextTick) {
      setTimeout(() => {
        const userData = new UserDataModel({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          accessTokenExpiration: data.accessTokenExpiration,
          refreshTokenExpiration: data.refreshTokenExpiration,
          userInfo: {
            userId: data.userInfo.userId,
            login: data.userInfo.login,
            fullName: data.userInfo.fullName,
            phone: data.userInfo.phone,
            email: data.userInfo.email,
          }
        });
        this.userData$.next(userData);
      }, 0);
    }
    if (!fromNextTick) {
      const userData = new UserDataModel({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        accessTokenExpiration: data.accessTokenExpiration,
        refreshTokenExpiration: data.refreshTokenExpiration,
        userInfo: {
          userId: data.userInfo.userId,
          login: data.userInfo.login,
          fullName: data.userInfo.fullName,
          phone: data.userInfo.phone,
          email: data.userInfo.email,
        }
      });
      this.userData$.next(userData);
    }
  }

  logout() {
    redirectTo(`${ITS_URL}/logout?service=${location.origin}/`);
    // TODO: TEMPORARY FIX ON STAGE IS OK
    // this._spinnerOverlayService.show();
    // const iframe = document.createElement('iframe');
    // iframe.setAttribute('src', `${ITS_URL}/logout?service=${location.origin}`);
    // iframe.style.display = 'none';
    // document.body.appendChild(iframe);
    // iframe.addEventListener('load', function () {
    //   this.parentNode.removeChild(this);
    //   redirectTo(`${ITS_URL}/login?service=${location.origin}/`);
    // });
  }

  login(path = '/'): Observable<any> {
    const ticket = AuthService.getTicket();
    const url = `${location.origin}${encodeURIComponent(path)}`;
    if (!ticket) {
      this.redirectExternalSsoAuth(url);
    } else {
      const serviceName = `${location.origin}${location.pathname}${encodeURIComponent(AuthService.modifiedQueryString())}`;
      return this._http.post(`${API_URL}/auth`,
        {
          ticket,
          serviceName,
        })
        .pipe(
          map((res: any) => {
            this.setUserData(res, false);
            this._goTo(`${location.pathname}${AuthService.modifiedQueryString()}`);
            return true;
          })
        );
    }
  }

  refresh(refreshToken: string): Observable<any> {
    return this._http.post(`${API_URL}/auth/refresh`, { refreshToken });
  }

  openExternalUserProfilePage() {
    return window.open(`${ITS_URL}/user/profile`);
  }

  private redirectExternalSsoAuth(url: string) {
    location.assign(`${ITS_URL}/login?service=${url}`);
  }

  private _goTo(uri: string, historyStateData?: any) {
    if (!historyStateData) {
      this._router.navigateByUrl(uri);
    } else {
      this._router.navigate([uri], { state: { data: historyStateData } });
    }
  }

}
