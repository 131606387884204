import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, Injector, NgModule } from '@angular/core';
import { throwError, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, take } from 'rxjs/operators';
import { AuthService } from '#shared/modules/services';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private _injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authService = this._injector.get(AuthService);
    const URIS_WO_TOKEN_NEEDED = ['auth'];

    if (URIS_WO_TOKEN_NEEDED.find(el => req.url.indexOf(el) !== -1)) {
      return next.handle(req)
        .pipe(
          filter((event: HttpEvent<any>) => event instanceof HttpResponse),
          catchError((err) => {
            return throwError(err);
          }),
        );
    }

    return authService.userData$
      .pipe(
        filter(v => !!v),
        take(1),
        switchMap((userData) => {
          const headerSettings = req.headers.keys().reduce((accumulator, key) => {
            accumulator[key] = req.headers.getAll(key);
            return accumulator;
          }, {});

          if (!headerSettings['Content-type']) {
            headerSettings['Content-type'] =  ['application/json'];
          }

          headerSettings['Authorization'] = `Bearer ${userData.accessToken}`;

          const headers = new HttpHeaders(headerSettings);
          const changedReq = req.clone({ headers });

          return next.handle(changedReq)
            .pipe(
              filter((event: HttpEvent<any>) => event instanceof HttpResponse),
              map(res => res),
              catchError((err) => {
                if (err.status === 401) {
                  return authService.refresh(userData.refreshToken)
                    .pipe(
                      catchError(() => {
                        authService.logout();
                        return of(null);
                      }),
                      tap((res) => {
                        authService.setUserData(res);
                      }),
                      switchMap((res) => {
                        headerSettings['Authorization'] = `Bearer ${res.accessToken}`;
                        const headers = new HttpHeaders(headerSettings);
                        const changedReq2 = req.clone({ headers });
                        return next.handle(changedReq2);
                      }),
                    );
                }
                if (err.status === 403 && err.url.includes('auth/refresh')) {
                  authService.logout();
                }
                if (err.status === 500) {
                  err.error = {
                    type: 'https://api.1cbn.ru/problems/common/internal-server-error',
                    instance: err.error.instance,
                    title: 'Внутренняя ошибка сервера',
                    detail: 'Произошла внутрення ошибка сервера',
                    status: 500,
                    httpMethod: 'POST',
                    requestTraceId: err.error.requestTraceId,
                    internalCode: err.error.internalCode,
                  };
                }
                return throwError(err);
              }));
        })
      );

  }

}

