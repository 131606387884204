<div class="breadcrumbs">
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <i nz-icon nzType="home"></i>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <span>Личный кабинет</span>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item *ngFor="let item of items">
      <ng-container *ngIf="item.routerLink">
        <ng-container *ngIf="item.queryParams">
          <a [routerLink]="[item.routerLink]" [queryParams]="item.queryParams">{{item.label}}</a>
        </ng-container>
        <ng-container *ngIf="!item.queryParams">
          <a [routerLink]="[item.routerLink]">{{item.label}}</a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!item.routerLink">
        <span>{{item.label}}</span>
      </ng-container>
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</div>
