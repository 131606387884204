
import { Injectable } from '@angular/core';
import { BreadcrumbItemModel } from './models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  items$: BehaviorSubject<BreadcrumbItemModel[]> = new BehaviorSubject(null);

  constructor() {}

  setItems(items: BreadcrumbItemModel[]) {
    setTimeout(() => {
      this.items$.next(items);
    }, 0);
  }

}
