import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '../services';
import { SpinnerOverlayService } from '../spinner-overlay/spinner-overlay.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  @Input() userData: any;

  constructor(
    private _authService: AuthService,
    private _spinnerOverlayService: SpinnerOverlayService,
  ) {}

  openExternalUserProfilePage() {
    this._authService.openExternalUserProfilePage();
  }

  logout() {
    this._authService.logout();
  }
}
