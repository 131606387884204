export function decodeBase64(regctxRaw: string) {
  const regctxRawModified = regctxRaw.replace(new RegExp(' ', 'g'), '+');
  return JSON.parse(
    decodeURIComponent(
      escape(
        atob(regctxRawModified)
      )
    )
  );
}
