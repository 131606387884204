import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './routes/root/root.component';
import { AuthGuard } from '#shared/modules/common';

const routes: Routes = [
  {
    path: 'login',
    data: { preload: false },
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
  }, {
    path: '',
    component: RootComponent,
    children: [
      {
        path: 'organizations',
        data: { preload: false },
        // tslint:disable-next-line: max-line-length
        loadChildren: () => import('./routes/root/children/organizations/organizations.module').then(m => m.OrganizationsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'organization-onboarding',
        data: { preload: false },
        // tslint:disable-next-line: max-line-length
        loadChildren: () => import('./routes/root/children/organization-onboarding/organization-onboarding.module').then(m => m.OrganizationOnboardingModule)
      }, {
        path: 'organization-profile-access',
        data: { preload: false },
        // tslint:disable-next-line: max-line-length
        loadChildren: () => import('./routes/root/children/organization-profile-access/organization-profile-access.module').then(m => m.OrganizationProfileAccessModule)
      }, {
        path: '**',
        redirectTo: 'organizations',
        pathMatch: 'full',
      }
    ],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

