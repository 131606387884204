import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { UserMenuComponent } from './user-menu.component';

@NgModule({
  declarations: [UserMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    NzIconModule,
    NzDropDownModule,
  ],
  exports: [UserMenuComponent],
})
export class UserMenuModule { }
