import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { IAccessKeyDescription } from './interfaces';
import { filter, map } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AccessKeysService {

  constructor(
    private _http: HttpClient,
  ) {}

  getAccessKey(organizationId: string): Observable<any> {
    return this._http.post(`${API_URL}/access-keys/obtain`, {
      organizationId,
    });
  }

  createAccessKey(obj: any): Observable<any> {
    return this._http.post(`${API_URL}/access-keys/create`, obj);
  }

  getAllAccessKeys(): Observable<IAccessKeyDescription[]> {
    return this._http.get<IAccessKeyDescription[]>(`${API_URL}/access-keys`);
  }

  getAllAccessKeysByOrganizationId(organizationId: string): Observable<any> {
    return this.getAllAccessKeys()
      .pipe(
        map((res) => {
          return res.filter(key => key.organization.id === organizationId);
        })
      );
  }

  deleteAccessKeyById(id: string): Observable<any> {
    return this._http.delete(`${API_URL}/access-keys/${id}`);
  }

}
