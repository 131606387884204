import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { DelayedPreloadingStrategy } from './preloading-strategy';
import { MultiplierPipe } from './multiplier.pipe';

@NgModule({
  declarations: [
    MultiplierPipe,
  ],
  exports: [
    MultiplierPipe,
  ],
  providers: [
    AuthGuard,
    DelayedPreloadingStrategy,
  ],
})
export class CommonModule {}
