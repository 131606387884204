import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { AccessKeysService } from './access-keys.service';
import { ResponsiveService } from './responsive.service';

@NgModule({
  imports: [
    CommonModule,
  ],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ServicesModule,
      providers: [
        ApiService,
        AuthService,
        CacheService,
        AccessKeysService,
        ResponsiveService,
      ],
    };
  }
}
