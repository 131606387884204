<div class="user-menu" *ngIf="userData?.login">
  <a nz-dropdown [nzDropdownMenu]="menu">
    {{userData?.login}}
    <i nz-icon nzType="down"></i>
  </a>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="openExternalUserProfilePage()">Профиль</li>
      <li nz-menu-divider></li>
      <li nz-menu-item (click)="logout()">Выйти</li>
    </ul>
  </nz-dropdown-menu>
</div>
