import { BrowserModule } from '@angular/platform-browser';
import {
  LOCALE_ID,
  APP_INITIALIZER,
  NgModule
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SpinnerOverlayModule } from '#shared/modules/spinner-overlay/spinner-overlay.module';
import { BreadcrumbsModule } from '#shared/modules/breadcrumbs/breadcrumbs.module';
import { UserMenuModule } from '#shared/modules/user-menu/user-menu.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NZ_I18N, ru_RU } from 'ng-zorro-antd';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzListModule } from 'ng-zorro-antd/list';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiFactory } from './config/api.factory';
import { RootComponent } from './routes/root/root.component';
import { RootNotificationsComponent } from './routes/root/root-notifications.component';
import {
  ServicesModule,
  CommonModule
} from '#shared/modules';
import { ApiInterceptorModule } from '#shared/modules/api-interceptor/api-interceptor.module';
import ru from '@angular/common/locales/ru';
import { ErrorsModule } from '#shared/modules/errors/errors.module';

registerLocaleData(ru);

@NgModule({
  declarations: [
    AppComponent,
    RootComponent,
    RootNotificationsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxWebstorageModule,
    CommonModule,
    ApiInterceptorModule,
    SpinnerOverlayModule,
    BreadcrumbsModule,
    UserMenuModule,
    ErrorsModule,
    NzLayoutModule,
    NzCardModule,
    NzNotificationModule,
    NzModalModule,
    NzFormModule,
    NzIconModule,
    NzStatisticModule,
    NzDividerModule,
    NzAlertModule,
    NzTimelineModule,
    NzDescriptionsModule,
    NzResultModule,
    NzListModule,
    NgxWebstorageModule.forRoot(),
    ServicesModule.forRoot()
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: NZ_I18N, useValue: ru_RU },
    {
      provide: APP_INITIALIZER,
      useFactory: ApiFactory,
      multi: true,
    }
  ],
  entryComponents: [RootNotificationsComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
