import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '#shared/modules/services/auth.service';
import { Subject } from 'rxjs';
import { ResponsiveService } from '#shared/modules/services/responsive.service';
import { SpinnerOverlayService } from '#shared/modules/spinner-overlay/spinner-overlay.service';
import { BreadcrumbsService } from '#shared/modules/breadcrumbs/breadcrumbs.service';
import { takeUntil, filter, map } from 'rxjs/operators';

@Component({
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit, OnDestroy {
  private _unsubscriber$: Subject<any> = new Subject();

  get breadcrumbsItems$() {
    return this._breadcrumbsService.items$.asObservable();
  }

  get userData$() {
    return this._authService.userData$.asObservable()
      .pipe(
        takeUntil(this._unsubscriber$),
        filter(res => !!res),
        map(res => res.userInfo || null)
      );
  }

  constructor(
    private _authService: AuthService,
    private _responsiveService: ResponsiveService,
    private _spinnerOverlayService: SpinnerOverlayService,
    private _breadcrumbsService: BreadcrumbsService,
  ) {}

  ngOnInit() {
    this._responsiveService.init();
  }

  ngOnDestroy() {
    this._unsubscriber$.next();
    this._unsubscriber$.complete();
  }

}

